@import "~bootstrap/scss/bootstrap";
@import "setting";
//===========================================

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  line-height: 1.3em;
}

.vPu-Space {
  height: 100vh;
  overflow: hidden;
  background-image: $board-bg-image;
  color: $app-main-text-color;

  .icon {
    font-size: 14px;
    margin: 3px;
  }

  .bootstrap-container {
    margin: 0 $gap;
    padding: 0;
  }

  textarea:focus,
  textarea.form-control:focus,
  input.form-control:focus,
  input[type=text]:focus,
  input[type=password]:focus,
  input[type=email]:focus,
  input[type=number]:focus,

  [type=text].form-control:focus,
  [type=password].form-control:focus,
  [type=email].form-control:focus,
  [type=tel].form-control:focus,
  [contenteditable].form-control:focus {
    box-shadow: inset 0 -1px 0 #ddd !important;
    outline: none;
  }

  [type="button"]:focus,
  .btn:focus,
  .btn:active {
    box-shadow: none;
    border: none;
  }

  .content-editable {
    background-color: inherit;
    border: none;
    font-size: inherit;
    font-weight: inherit;
    cursor: pointer;
    padding: 0;
    transition: all .3s ease-in-out;

    &:focus {
      font-weight: 400;
      cursor: text;
      background-color: #fff;
      border: 1px solid $input-border-color;
      box-shadow: inset 0 -1px 0 #ddd;
    }
  }

  .cancel-icon {
    margin-left: $gap;

    i {
      color: #fff;
      transition: all .3s linear;
    }
  }

}