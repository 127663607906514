@import "_setting.scss";

$appbar-bg-color: #09203f;

.navbar-app {
  padding-left: $gap;
  display: flex;
  align-items: center;
  background-color: $appbar-bg-color;
  font-size: 1.5rem;
  height: $navbar-app-height;
  color: #fff;
}