@import "_setting.scss";

$column-height: calc(#{$board-content-height} - #{$gap * 2});

.column {
  flex: 0 0 auto;
  width: 300px;
  height: $column-height;

  +.column {
    margin-left: $gap;
  }

  >* {
    background-color: $column-bg-color;
    padding: 0 8px;
  }

  header {
    cursor: pointer;
    padding: 5px 8px !important;
    height: $column-header-height;
    line-height: 28px;
    font-size: 16px;
    font-weight: 600;
    border-radius: $column-border-radius $column-border-radius 0 0;
    display: flex;
    justify-content: space-between;

    .column-title {
      flex: 90%;
    }

    .column-dropdown-actions {
      flex: 10%;

      .dropdown-btn {
        padding: 0 $gap;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        background-color: $column-bg-color;
        color: $app-main-text-color;
        border: none;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: darken($column-bg-color, 10);
        }

        &::after {
          content: "...";
          border: none;
        }
      }

      .dropdown-menu {
        padding: 0;
        min-width: 200px;

        a.dropdown-item {
          padding: .1rem 1rem;
          font-size: 14px;

          &:first-child {
            border-top-left-radius: 0.25rem;
          }


          &:last-child {
            border-bottom-right-radius: 0.25rem;
          }
        }
      }
    }
  }

  footer {
    color: #888;
    padding: 5px 8px !important;
    // height: 36px;
    line-height: $column-footer-height;
    border-radius: 0 0 $column-border-radius $column-border-radius;

    .footer-actions {
      margin-left: $gap;
      cursor: pointer;
      transition: all .3s linear;
      user-select: none;

      &:hover {
        color: $app-main-text-color;
      }
    }
  }

  .card-list {
    padding-top: 5px;
    list-style-type: none;
    max-height: calc(#{$column-height} - #{$column-header-height} - #{$column-footer-height});
    overflow-y: auto;
    margin: 0;

    &::-webkit-scrollbar {
      -webkit-appearance: none;

      &:vertical {
        width: 11px;
      }

      &-thumb {
        background-color: darken($column-bg-color, 15);
        border-right: 5px solid $column-bg-color;
      }
    }

    .card-ghost {
      transition: transform 0.18s ease;
      transform: rotateZ(2deg);
      -webkit-transform: rotateZ(2deg);
      -moz-transform: rotateZ(2deg);
      -ms-transform: rotateZ(2deg);
      -o-transform: rotateZ(2deg);
      font-weight: 600;
    }

    .card-ghost-drop {
      transition: transform 0.18s ease-in-out;
      transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
      -moz-transform: rotateZ(0deg);
      -ms-transform: rotateZ(0deg);
      -o-transform: rotateZ(0deg);
      font-weight: 600;
    }

    .cards-drop-preview {
      background-color: rgba(150, 150, 200, 0.1);
      border: 1px dashed #abc;
      margin: 5px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }

    .add-new-card-area {
      .textarea-enter-new-card {
        min-height: 54px;
        max-height: 162px;
        // margin-bottom: $gap;
      }
    }
  }
}