@import "_setting.scss";


.board-contents {
  height: $board-content-height;
  // display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 10px;
  display: flex;

  &::-webkit-scrollbar {
    -webkit-appearance: none;

    &:horizontal {
      height: 11px;
    }

    &-thumb {
      background-color: $common-elememnt-bg;
      border-right: 5px solid $column-bg-color;
      border-left: 5px solid $column-bg-color;

    }
  }

  .smooth-dnd-container {
    .smooth-dnd-draggable-wrapper {
      &:not(:first-child) {
        .column {
          margin-left: $gap;
        }
      }
    }
  }

  .column-drag-handle {
    cursor: move;
    padding: 5px;
  }

  .column-drop-preview {
    background-color: rgba(201, 201, 245, 0.1);
    border: 1px dashed rgb(114, 131, 148);
    margin: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .add-new-column {
    width: 300px;
    max-width: 300px;
    background-color: hsla(0, 0%, 100%, .14);
    border: 1px dashed rgb(114, 131, 148);
    margin-left: $gap;
    border-radius: $column-border-radius;
    -webkit-border-radius: $column-border-radius;
    -moz-border-radius: $column-border-radius;
    -ms-border-radius: $column-border-radius;
    -o-border-radius: $column-border-radius;
    color: #fff;
    padding: 0 8px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
    user-select: none;

    &:hover {
      background-color: hsla(0, 0%, 100%, .34);
    }
  }

  .enter-new-column {
    width: 300px;
    max-width: 300px;
    background-color: $column-bg-color;
    line-height: 32px;
    padding: 10px;
    margin-left: $gap;
    border-radius: $column-border-radius;

    input.input-enter-new-column {
      margin-bottom: 5px;

      &:focus {
        border-color: $input-border-color;
      }
    }
  }
}